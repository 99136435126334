<template>
  <div class="page-my-categories">
    <n-loader :loading="$var('load')" />
    <top-bar back :title="$app.secure.clean($n.t('10.11'))" backroute="profile" />
    <div class="container">
      <div class="wrap">
        <div class="wrap">
          <div v-for="item in categories" :key="item.id" class="item-wrap"
               :class="{'selected': selected.includes(item.id)}" @click="selectCategory(item)">
            <div class="content-wrapper">
              <div class="img" v-html="item.image"></div>
              <div class="title">{{ item.name }}</div>
            </div>
          </div>
          <n-button class="button-next" color="yellow" wide @click="save()"><t name="3.13" /></n-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageMyCategories',
  data() {
    return {
      categories: [],
      selected: [],
      user: $app.auth.user(),
      userCategories: JSON.parse($app.auth.user().categories),
    }
  },
  created() {
    this.loadCategories()
  },
  methods: {
    save() {
      this.$var('load', true)
      $api.user.edit($app.auth.user().id, {categories: this.selected}).then(() => {
        $app.store.action('app.updateUser').finally(() => {})
      }).finally(() => {
        this.$var('load', false)
      })
    },
    selectCategory(item) {
      if (this.selected.includes(item.id)) {
        if (this.selected.length > 1) {
          this.selected = $n.filter(this.selected, (e) => {
            if (e !== item.id) {
              return e
            }
          })
        }
      } else {
        if (this.selected.length < 3) {
          this.selected.push(item.id)
        }
      }
    },
    loadCategories() {
      this.$var('load', true)
      $api.categories.get().with('children').all().then((response) => {
        this.categories = response.data.content
        this.selected = []
        $n.each(this.categories, (e) => {
          if (this.userCategories.includes(e.id)) {
            this.selected.push(e.id)
          }
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.page-my-categories {
  .add-category {
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: space-between;
    .button-next {
      margin-left: 15px;
      width: 47px;
      height: 47px;
      text-align: center;
    }
  }
  .add-category {
    margin-top: 20px;
  }
  .button-next {
    margin-top: 20px;
  }
  .wrap {
    .item-wrap {
      display: flex;
      align-items: center;
      margin-top: 25px;
      border-radius: 18px;
      padding: 10px;
      border: 5px solid #c7c5c5;
      &.selected {
        border: 5px solid var(--primary);
      }
    }
    .content-wrapper {
      display: flex;
      align-items: center;
      .title {
        font-size: 18px;
        margin-bottom: 0;
        text-align: left;
      }
      .img {
        width: 52px;
        height: 52px;
        margin-right: 15px;
        ::v-deep svg {
          width: 100%;
          height: 100%;
          object-fit: contain;

        }
      }
    }
  }
}
</style>
